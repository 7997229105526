
#recommendations-page-header {
    font-size: 1.5rem;
    margin-left: 5%;
    margin-bottom: 0;
    }

#recommendations-page-intro {
    font-size: 1rem;
    margin-top: 10px;
    margin-left: 5%;
    margin-right: 5%;
}
#recommendations-page-none-selected {
    font-size: 2rem;
    text-align: center;
    align-self: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: 5%;
    margin-right: 5%;
}

#recommendations-checkbox {
    margin: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1rem; /* Adjust font size as needed */

    input {
        margin-right: 8px; /* Adjust spacing between checkbox and label */
        width: 20px;
        height: 20px;
        border: 2px solid #2196F3; /* Border color for the checkbox */
        outline: none;

        &:checked {
        }
    }

    &:hover {
        text-decoration: underline; /* Underline label on hover */
    }
}

.recommendations-checkbox-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    margin: 0 auto;
    margin-right: 5%;
}