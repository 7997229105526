
    @import '../_gradients.scss';


.breathe-card {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-gap: 2%;
    border: 1px solid var(--primarycolor);
    margin-bottom: 15px;
    background-image: $container-gradient;
    max-width: 100%;
    transition: background-color 0.3s ease;
    opacity: 0.9;
}


.breathe-card li {
    font-size: 1.2rem;
}

.breathe-card-title {
    grid-row: 1;
    grid-column: 1;
    text-align: center; /* Align the title to the center */
    font-weight: 800; /* Optionally make the title bold */
}

.breathe-card-image {
    float: left;
    grid-row: 1 / span 2; /* Span the image over two rows */
    grid-column: 2;
    width: 25%; /* Set the width to 100% of the container */
    max-width: 50%; /* Set a maximum width for the image */
    height: auto; /* Maintain aspect ratio */
    margin-right: 3%;
}

.breathe-card-text {
    grid-row: 3;
    grid-column: 1;
    margin-right: 2%;
    text-align: justify; /* Optionally justify the text */
}

@media only screen and (max-width: 767px) {
    .breathe-card-image {
        width: 40%; /* Set the width to 100% of the container */
        height: auto; /* Maintain aspect ratio */
        margin-right: 3%;
    }

    .breathe-card li {
        font-size: 1.1rem;
        text-align: left;
    }
}