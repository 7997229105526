/* Services.css 

    #176d7a - Darkest
    #6cafb6 - Accent
    #e1aeae - Accent
    #e9c3c3 - Unused
    #e5cec2 - Links
    #f5e2db - Lightest - Background

*/


.services-container-intro {
    padding-left: 5%;
    padding-right: 5%;

    p {
        text-align: justify;
        text-align-last: center;
    }
}

.services-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    justify-content: space-between;
    margin-bottom: 10px;
}

.service {
    position: relative;
    height: 300px;
    width: calc(30%);
    margin: 1%;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.service:hover {
    transform: scale(1.1);
}

.services-container-wrapper {
    display: grid;
    place-items: center; /* Center both horizontally and vertically */
}

.services-container-header {
    margin: 0 auto;
    /* Additional styles */
    padding: 2.5%;
    padding-bottom: 10px;

}
    .service-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1;
    }

    .service-image:hover {
        opacity: 0.75;
        transition: opacity 1.5s ease;
    }

    .service-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 90%;
        height: auto;
        color: #fff;
        padding: 20px;
        background-color: var(--primarycolor);
        opacity: 0.8;
        transition: opacity 0.5s ease;
    }

    .service:hover .service-overlay {
        opacity: 1;
        transition: opacity 0.5s ease;
    }

    .service:hover .service-image {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        opacity: 1;
    }

.service-overlay h2,
.service-overlay p,
.service-overlay a {
    margin: 0;
    color: #fff;
    text-align: center;
}

    .service-overlay h2 {
        padding-bottom: 10px;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .service-overlay a {
        color: #6cafb6;
        text-decoration: none;
        font-weight: bold;
        transition: color 0.3s ease;
    }
.service-overlay p {
    font-size: 1rem;
}
.service-overlay a:hover {
    color: #fff;
}
    /* Adjust width for single column on mobile */
    @media (max-width: 968px) {

        .services-container-wrapper {
            display: grid;
            grid-template-columns: 1fr;
            width: 100%;
            margin: 0 auto;
            padding-top: 10px;
        }

        .service {
            width: 100%;
            padding: 3%;
        }

        .service-image {
            opacity: 0.9;
        }

        .service-overlay {
            width: 85%;
        }

        .service-overlay p,
        .service-overlay a {
            font-size: 1rem;
        }

        .service-overlay h2 {
            font-size: 1.5rem;
        }
    }
