.contact-form-container {
    display: flex;
    width: 100%;
    margin: 0 auto;
}

.contact-form-container-wrapper {
    display: grid;
    grid-template-columns: 40% 55%;
    grid-gap: 5%;
    width: 100%;
    height: auto;

}

.contact-form-map-container {
    grid-column: 1;
    grid-row: 1;
    justify-content: center;
    width: 100%;
    height: auto;
}

.contact-form-input-boxes {
    grid-column: 2;
    grid-row: 1;
    width: 100%;
}


.form-group {
    margin-bottom: 10px;
}

.message-box {
    width: 100%; /* Adjust the width as needed */
}

.message-box textarea {
    width: 100%; /* Adjust the width as needed */
    height: 150px; /* Adjust the height as needed */
    resize: vertical; /* Allow vertical resizing of the textarea */
}

label {
    display: block;
    margin-bottom: 5px;
}

input,
textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 2px solid black;
    box-shadow: 2px 2px 2px var(--primarycolorhalf);
    box-sizing: border-box;
}

button {
    background-color: var(--primarycolor);
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;
}

button:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
    }

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.thank-you-message {
    background-color: var(--primarycolor);
    color: #fff;
    padding: 20px;
    margin-top: 20px;
}

.captcha-submit-buttons-container {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
    place-items: start;
    align-items: center;
}

#captcha {
    width: 100%;
    grid-column: 2;
    grid-row: 1;
}

#contact-form-submit-button {
    grid-column: 1;
    grid-row: 1;
}
/* ADD MOBILE FORMATTING */
@media only screen and (max-width: 767px) {
    .contact-form-container {
        display: block;
    }

    .contact-form-container-wrapper {
        display: block;
    }

    .captcha-submit-buttons-container {
        display: block;
        padding-bottom: 25px;
    }

    #captcha {
        padding-bottom: 10px;
    }
}
