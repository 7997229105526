
#mission-statement {
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 80%;
    text-align: center;

    p {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 768px) {

    #mission-statement {
        width: 90%;
        justify-self: center;

        p {
            font-size: 1.1rem;
        }
    }
}
