@import '../_gradients.scss';

.header-container {
    background-image: $header-gradient;
    z-index: 1001;
}
.header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Optional: Align items vertically if needed */
    padding: 10px; /* Adjust as needed */
    z-index: 1001;

    button:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        cursor: pointer;
    }
}

.header-wrapper button {
    margin: 10px;
    color: var(--primarycolor);
    background-color: #fff;
    font-size: 1rem;
    opacity: 0.75;
    box-shadow: rgba(0,0,0,0.4) 5px 5px;
    z-index: 1001;
}


.header-wrapper a {
    text-decoration: none;
    font-family: var(--primary-font);
    opacity: 1;
    z-index: 1001;
}

.client-portal-login-button-icon {
    margin-left: 10px;
}
#header-client-portal-login-button {
    align-self: flex-start;
   
}
#header-contact-button {
    align-self: flex-end;
    text-align: start;
}
.header-contact-button-icon {
    margin-right: 10px;
}

@media (max-width: 768px) {
    .header-wrapper button {
        font-size: .7rem;
    }
    }