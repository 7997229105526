/* PrivacyPolicy.scss */

.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

h2 {
    color: #333;
}

p {
    margin-bottom: 15px;
    line-height: 1.5;
}
