// File: /src/components/OfficeStatus.scss

@import "../_gradients.scss";

.office-status {
    position: sticky;
    top: 0;
    width: 100%;
    background-image: linear-gradient(90deg, var(--primarycolor), var(--accentcolor));
    color: var(--primarytext);
    border-bottom: 2px solid var(--accentcolor);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 10; // Keeps it above other content, but below the nav menu

    &-container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 1rem 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 767px) {
            padding: 1rem;
        }
    }

    &-message {
        h4 {
            font-size: 1.5rem;
            font-weight: bold;
            margin: 0;
            color: var(--primarycolorlight);
        }

        p {
            font-size: 1rem;
            margin: 0.5rem 0;

            .status-highlight {
                color: var(--dangercolor);
                font-weight: bold;
            }

            .status-date {
                color: var(--successcolor);
                font-weight: bold;
            }
        }
    }

    &-close {
        font-size: 1.5rem;
        font-weight: bold;
        background: none;
        border: none;
        color: var(--primarytext);
        cursor: pointer;
        transition: transform 0.2s ease, color 0.2s ease;

        &:hover {
            color: var(--dangercolor);
            transform: scale(1.2);
        }
    }
}
