

.contact-intro-container {
    display: flex;
    width: 100%;
    margin: 0 auto;
}

.contact-intro-banner {
    background-image: url('../../assets/contact/beach-1852945_1920.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    width: 100%;
    height: auto;
    padding-top: 25px;
    padding-bottom: 25px;
    place-content: center;
    place-items: center;
    justify-content: center;
}
    .contact-intro-banner h5 {
        padding-bottom: 0px;
        font-weight: 800;
        color: black;
        text-shadow: 1px 1px 0 white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white;
        padding-bottom: 10px;
    }
    .contact-intro-banner h1 {
        padding-bottom: 0px;
        font-weight: 600;
        color: black;
        text-shadow: 1px 1px 0 white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white;
    }
    .contact-intro-banner-description {
        width: 100%;
        padding-left: 10%;
    }



@media only screen and (max-width: 767px) {
    .contact-intro-container {
        display: flex;
        flex-direction: column; /* Stack elements vertically on small screens */
        width: 100%;
        margin: 0 auto;
    }

    .contact-intro-container-wrapper {
        display: grid;
        grid-template-columns: 100%; /* Full-width columns on small screens */
        grid-template-areas: "desc" "image"; /* Reorder the areas for better stacking */
    }

    .contact-intro-description {
        grid-area: desc;
        padding: 20px; /* Reduce padding for smaller screens */
    }

    .contact-intro-image {
        display: none;
    }

    .contact-intro h1 {
        margin-bottom: 10px; /* Reduce margin for smaller screens */
        padding-bottom: 10px; /* Reduce padding for smaller screens */
    }


    }

    