.map-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: block;
    justify-content: center;
    align-items: center;
}

.map-container-wrapper {
    height: 100%; /* Adjust the height as needed */
    width: 100%;
    }

    .map-container-wrapper iframe {
        height: 80vh;
        width: 100%;
    }

    @media (max-width: 768px) {
        .map-container-wrapper iframe {
            height: 40vh;
        }
    }
