@import "../_gradients.scss";


.about-container {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
}

.map-section {
    height: 500px;
}

.owner-info,
.office-info {
    background-image: $container-gradient;
}
.mission-info {

    background-color: var(--backgroundcolor);
    border: 0px;
    color: var(--primarycolor);
}

.carousel-container {
    max-width: 50%;
    align-self: center;
    margin: 0 auto;
}
/* Add additional styling based on your preferences */
@media screen and (max-width: 768px) {
    .owner-info,
    .office-info {
        font-size: 1rem;
        width: 100%;
    }

    .mission-info p {
        font-size: 1.2rem;
        width: 100%;
        margin: 0 auto;
        justify-self: center;
    }

    .owner-image,
    .office-image {
        width: 100%;
        max-height: 250px;
        object-fit: cover;
        margin-bottom: 20px;
    }
    .carousel-container {
        max-width: 90%;
    }
}
