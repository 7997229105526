/* HomeIntro.scss */
@import "../_gradients.scss";
.home-page-logo {

    transform: translateY(-100%);
    transform: scale(3);
    opacity: 0;
    /* Apply a transition for a smooth scale change */
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}
#chaos-container {
    height: 300px;
    width: 100%;
}

#home-page-logo-id {
    width: 35%;
    height: auto;
    margin: 0;
    padding: 0;

}

#home-content-image-id {
    opacity: 1;
    z-index: 1;
}

.home-social-icon {
    font-size: 2rem;
    margin: 0 auto;
    display: flex;
}

.intro-section {
    text-align: center;
    width: 100%;
}

.home-content-section {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/home/clouds-8459053_1920.webp);
    background-size: cover;
    background-position: center;
    width: 100%;
    margin-bottom: 20px;
}

.home-content-wrapper {
    flex-grow: 1;
    max-width: 100%;
    margin: 2.5%;
    background-color: var(--accentcolor);
    background-image: $container-gradient;
    display: grid;
    grid-template-areas:
        "b c";
    grid-template-columns: 20% 80%;
    grid-gap: 1%;
    padding: 1%;
    opacity: 1;
    box-shadow: 0px 0px 10px 5px var(--primarycolorhalf);
}




.content-info {
    grid-area: c;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    align-content: center;
    justify-content: center;
    text-align: center;

    p {
        text-align: justify;
        text-align-last: left;
    }
}

.home-content-image {
    grid-area: b;
    width: 100%;
    height: auto;
    object-fit: contain;
    align-self: center;
    opacity: 1;
}

@media (max-width: 767px) {


    #home-page-logo-id {
        width: 60%;
        height: auto;
    }


    .home-content-section {
        flex-direction: row; /* Arrange elements horizontally */
        width: 100%;
        height: auto;
    }

    .home-content-wrapper {
        background-color: var(--accentcolor);
        display: grid;
        grid-template-columns: 34% 24% 14% 24%;
        grid-template-areas:
            "c c c c" "b b b b" "d d d d";
        grid-gap: 1%;
        padding: 5%;
        border: 1px solid var(--primarycolor);
        place-items: center;
        margin-top: 20px;
    }

    .content-info {
        width: 100%;
margin: 0 auto;
    }
    .home-content-wrapper {
        max-width: 95%; /* Remove maximum width on smaller screens */
        padding-left: 20px;
        padding-right: 20px;
        opacity: 1;
    }

    #home-content-image-id {
        opacity: 1;
    }
    #chaos-container {
        height: 250px;
        width: 100%;
    }
    /* Styles for tablet and phone screens */
}
