@import '../_gradients.scss';

.owner-info-container {
    display: flex;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    

    ul {
        list-style-type: none;
        padding: 0; // Optional: Remove default padding
        margin: 0; // Optional: Remove default margin
    }

    h2 {
        font-weight: 600;
    }
}

.owner-info-container-wrapper {

    margin-top: 0;
    padding-top: 0;
    display: grid;
    grid-template-columns: 40% 60%;
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: 100%;
    grid-template-areas: "image desc" "toggles  toggles";
}

.owner-info-image-container {
    width: 80%;
    display: block;
    justify-content: center;
    align-items: center;
    place-content: center;
    place-self: center;
}

#about-owner-image {
    grid-column: 1;
    margin: 0;
    padding: 0;
    margin-left: 0;
    margin-bottom: 25px;
    margin-top: 25px;
    grid-area: image;
    width: 90%;
    height: auto; /* Adjust height as needed */
    border: 20px solid var(--altaccentcolor);
    align-self: center;
    box-shadow: 0px 0px 10px 5px var(--primarycolorhalf);
}

.owner-info-description {
    width: 90%;
    grid-area: desc;
    padding-right: 5%;
    margin-top: 5%;
    text-align: justify;
    align-items: start;
    place-self: flex-start;

    }

    .owner-info-toggles {
        display: flex;
        flex-grow: 0;
        gap: 10px; /* Adjust the gap between items as needed */
        padding-top: 25px;
        padding-bottom: 25px;
        grid-area: toggles;
        height: auto;
        text-align: center;
        justify-content: space-evenly;
        place-items: flex-start;
        background-color: var(--primarycolor);

    }


    .owner-info-popdown {
        display: block'';
        margin: 0 auto;
        text-align: center;
        justify-content: center;
        text-align: justify;
        max-width: 20vw;
    }

.toggle-section {
    max-width: 33%;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 0;

    h2 {
        font-size: 1.5rem;
        color: var(--backgroundcolor);
    }

    h2:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease;
        cursor: help;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        font-size: 1rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 10px;
        line-height: 1.5;
        text-align: center;
        color: var(--primarycolor);
    }


    li:nth-child(odd) {
        background-color: var(--neutralcolor);
    }
    /* Apply styles for every other list item */
    li:nth-child(even) {
        background-color: var(--neutralcolor);
    }
}

    .toggle-icon {
        margin-right: 10px;
    }
    /* Apply these styles to the owner-info-popdown class */


    @media only screen and (max-width: 767px) {
        .owner-info-container {
            display: block;
            flex-direction: column; /* Stack elements vertically on small screens */
            width: 100%;
        }

        .owner-info-popdown {
            margin: 0 auto;
            width: 100%;
            max-width: 80vw;
        }

        .toggle-section {
            margin-bottom: 25px;
        }

        #owner-info-funfacts {
            margin-bottom: 0px;
        }

        .owner-info-container-wrapper {
            order: 1;
            display: block;
            width: 100%;
            height: 100%;
        }

        .owner-info-image-container {
            order: 1;
            margin: 0 auto;
            justify-content: center;
            align-content: center;
            align-self: center;

            h5 {
                font-size: 1rem;

                h2 {
                    font-size: 1.5rem;
                }
            }
        }

        .owner-info-description {
            order: 2;
            width: 100%;
            place-self: center;
            padding-left: 5%;
            padding-right: 5%; /* Reduce padding for smaller screens */

        }

        .owner-info-toggles {
            order: 3;
            display: flex;
            flex-direction: column; /* Align items vertically */
            align-items: center;
            justify-content: center;
            text-align: center; /* Optional, if you have text content */
            width: 100%;
            height: auto; /* Adjust height as needed */
        }


        .toggle-section {
            width: 100%;
            max-width: 100%;
            align-self: center;

            li {
                width: 100%;
            }
        }


        .owner-info h2 {
            margin-bottom: 10px; /* Reduce margin for smaller screens */
            padding-bottom: 10px; /* Reduce padding for smaller screens */
        }

        .owner-info-container-wrapper {
            display: flex;
            flex-direction: column;
        }
    }
