$gradient: linear-gradient(to bottom, var(--backgroundcolor), #fff, var(--backgroundcolor));

.navigation-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0.925;
    padding: 10px; /* Adjust the padding as needed */
    position: sticky;
    max-height: 100px;
    top: 0; /* Stick to the top of the viewport */
    background-color: var(--backgroundcolor); /* Optional: Add a background color for better visibility */
    z-index: 1000;
}

.navigation-menu p {
    font-size: 2rem; /* Adjust the font size as needed */
    color: var(--primarycolor);
}

.menu-logo-container {
    text-align: center;
    width: 200px;
    z-index: 1001;
}


/* Initially hide the logo */
.menu-logo-container.hide-logo {
    opacity: 0;
    visibility: hidden;
}

/* Fade in the logo when the class is removed */
.menu-logo-container.fade-in-logo {
    opacity: 1;
    visibility: visible;
}

.menu-logo-container img {
    width: 175px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    max-height: 100px;
    z-index: 1001;
    transition: transform 0.3s ease-in-out;
}

.buttonsLeft,
.buttonsRight {
    flex: 1;
    display: flex;
    align-items: center;
}

.buttonsLeft {
    justify-content: flex-end; /*Does not Work On Safari*/
}

.buttonsLeft button,
.buttonsRight button {
    margin: 0 10px;
    color: var(--primarycolor);
    font-family: var(--secondary-font);
    background: none;
    font-size: 1.6rem;
    font-weight: 500;
    transition: transform 0.3s ease-in-out;
}

.buttonsLeft button:hover,
.buttonsRight button:hover,
.menu-logo-container img:hover {
    transform: scale(1.1);
    text-decoration: underline;
    color: var(--primarycolor);
}

/*old*/
.mobile-menu-toggle {
    color: var(--primarycolor);
    display: none;
    font-size: 1.25rem;
    cursor: pointer;
    padding: 10px;
    transition: transform 0.3s ease-in-out;
    z-index: 1001;
}

/*old*/

.mobile-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background-image: $gradient;
    background-size: cover;
    background-color: var(--accentcolor); /* Adjust as needed */
    transform: translateY(-200%); /* Initially hide the menu above the toggle button */
    transition: transform 0.5s ease-in-out;
    z-index: -1;
}
/*old*/


.mobile-menu.visible {
    bottom: 10px;
    top: 0;
    margin-top: 50px;
    position: absolute;
    transform: translateY(20px); /* Initially hide the menu above the toggle button */
}
.mobile-menu.not-visible {
    transform: translateY(-200%); /* Initially hide the menu above the toggle button */
    bottom: 10px;
    top: 0;
    margin-top: 50px;
    position: absolute;
    transition: transform 0.5s ease-in-out;
}

.mobile-menu-text {
    margin-right: 10px;
    margin-left: 10px;

}


.mobile-menu button {
    width: 100%;
    color: var(--primarycolor);
    text-decoration: none;
    font-family: var(--secondary-font);
    background: none;
    font-size: 1.25rem;
    font-weight: 600;
    background-color: var(--backgroundcolor);
    z-index: -1;
}

    .mobile-menu button:hover {
        text-decoration: underline;
        background-color: var(--primarycolor);
        color: #fff;
    }
    /* Mobile Menu Styles */



.close-icon,
.icon-menu {
    transform: rotate(0); /* Initial rotation angle */
    transition: transform 0.3s ease;
}

.close-icon.spin,
.icon-menu.spin {
    transform: rotate(180deg); /* Final rotation angle */
    transition: transform 0.3s ease;
}

.icon-menu {
    margin-left: 0px;
    margin-right: 10px;
}
.close-icon,
.icon-menu {
    animation: spin 0.3s ease-in-out forwards; /* Add the animation */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

.close-icon.spin,
.icon-menu.spin {
    animation-name: spin-reverse; /* Use a reverse animation for closing */
}

@keyframes spin-reverse {
    0% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

    @media (max-width: 768px) {
        .navigation-menu {
            flex-direction: row;
            justify-content: space-between; /* Align logo left, menu button right */
            align-items: center;
            max-height: 80px;
        }

        .menu-logo-container {
            text-align: left; /* Position logo on the left */
            width: auto; /* Allow logo to take its natural width */
            top: 20px;
            left: 0;
        }
        .menu-logo-container img {
            width: 150px; /* Adjust the width as needed */
            height: auto; /* Maintain aspect ratio */
            max-height: 120px;
            z-index: 1001;
            margin-left: 15px;
            transition: transform 0.3s ease-in-out;
        }
            .mobile-menu-toggle {
                order: 1; /* Ensure menu button is displayed on the right */
            }



            .buttonsLeft,
            .buttonsRight {
                display: none; // hide desktop buttons on mobile
            }

            .mobile-menu-toggle {
                display: block; /* Show on smaller screens */

            }
        }