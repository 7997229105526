/* Layout.scss */

/* 
Color Pallet
        --primarycolor: #2966b7; 
--primarycolorhalf: rgba(41, 102, 183, 0.5)
--accentcolor: #f5e2db
; /*Containers - Other Sections #f5e2db
--altaccentcolor: #e5cec2
;
--wavecolorone: #fbcca0
; /*#e5cec2
--wavecolortwo: #fde8bd
;
--altbackgroundcolor: rgb(214, 116, 217)
;
--backgroundcolor: rgb(225, 255, 255)
; /* Light Blue*
--main-font: 'Lora', serif
;
--secondary-font: 'Playfair Display', serif
;
--heading-font: 'Quicksand', sans-serif
;
--elegant-font: 'Josefin Slab', serif
;
--ultra-font: 'Ultra', serif
;
    #176d7a - Darkest
    #6cafb6 - Accent
    #e1aeae - Accent
    #e9c3c3 - CONTAINERS 
    #e5cec2 - Links
    #f5e2db - Lightest - Background
        box-shadow: 0 50px 0px #6cafb6 inset, /* shadow at the top (inset) 
0px 500px 0px #e1aeae; /*  shadow at the bottom 

    OPT - #3f48cc
*/

:root {
    --primarycolor: #4d707d; /* Logo Color - Main Site Color */
    --primarycolorhalf: rgba (77, 112, 125, 0.5); /* Logo Color - Main Site Color */
    --altaccentcolor: #e2cfc8; /*Containers - Other Sections #f5e2db*/
    --accentcolor: #e5cec2;
    --neutralcolor: #d2cdc1;
    --wavecolorone: #4d707d; /*#e5cec2*/
    --wavecolortwo: #e2cfc8;
    --altbackgroundcolor: rgb(226, 207, 200);
    --backgroundcolor: rgb(235, 236, 230);
    --main-font: 'Lora', serif;
    --secondary-font: 'Playfair Display', serif;
    --heading-font: 'Quicksand', sans-serif;
    --elegant-font: 'Josefin Slab', serif;
    --ultra-font: 'Ultra', serif;
}


h1, h2, h3, h4, h5, h6 {
    font-family: var(--heading-font);
}

p {
    font-size: 1.2rem;
    font-weight: 500;
    font-family: var(--main-font);
}

li {
    font-size: 1.25rem;
    font-weight: 600;
}
h1 {
        font-weight: 600;
        font-style: italic;
        margin: 0;
        font-size: 1.5rem;
        padding-bottom: 10px;

        .large {
            font-weight: 700;
            text-transform: uppercase;
            font-style: normal;
            font-size: 2.25rem;
        }
    }


body {
    font-family: var(--main-font);
    font-size: 1.1rem;
    line-height: 1.25;
}


.layout-container main {
    margin-top: 10px;
    width: 100%;
    padding: 0;
}

.layout-container {
    width: 100%;
    background-color: var(--backgroundcolor);
}
/* Layout.scss */

.page-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.badges-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
}

@media (max-width: 768px) {
    h1 {
        font-weight: 600;
        font-style: italic;
        margin: 0;
        font-size: 1.25rem;
        padding-bottom: 10px;

        .large {
            font-weight: 700;
            text-transform: uppercase;
            font-style: normal;
            font-size: 1.75rem;
        }
    }
    p {
        font-size: 1rem;
    }
    }