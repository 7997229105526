/* NavMenu*/

 
/* Desktop Logo Style (For Footer)*/

.reset-button {
    margin-top: 20px;
text-align:center;
}
.board {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    width: 350px;
    background-color: var(--accentcolor);
}

    .row {
        display: flex;
        margin-bottom: 10px;

    }

.square {
    
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    background-color: #fff;
    text-align: center;
    font-size: 48px;
    cursor: pointer;
    color: #000000;
    font-weight: 800;
}