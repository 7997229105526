
.info-details-container {
    width: 100%;
    display: flex;
}

.contact-info-container-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 40% 55%; /* Two columns for buttons */
    grid-gap: 2%;
    align-items: flex-start; /* Center vertically */
    justify-content: space-between;

    a {
        word-wrap: anywhere;
        font-size: 1.35rem;
        text-decoration: none;
        color: #000;
    }

    a:hover {
        text-decoration: underline var(--primarycolor);
        transition: transform 0.3s ease;
    }
}

.contact-info-details-container {
    grid-column: 1;
    grid-row: 1;
}

.contact-info-description-container {
    grid-column: 2;
    grid-row: 1;
    text-align: justify;
    place-self: flex-end;

    h1 {
        text-align: left;
    }
}


#franki-email-address,
#facebook-contact-detail,
#instagram-contact-detail,
#phone-contact-detail {
    margin-left: 5px;
}


@media (max-width: 768px) {
    .contact-info-container-wrapper {
        display: block;
    }

    .contact-info-details-container {
        margin: 0 auto;
    }

    .contact-info-description-container {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .contact-info-container-wrapper a {
        font-size: 1.2rem;
    }

    #franki-email-address {
        font-size: .9rem;
    }
}
