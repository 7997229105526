
/* Home.css */
@import '../_gradients.scss';

.more-content-section {
    width: 100%;
    margin-top: 20px;
}

.more-content-wrapper {
    margin: 0 auto;
    margin-bottom: 20px;
    background-image: $container-gradient;
    width: 90%;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-areas:
        "b a" "b c" "b d";
    grid-gap: 1%;
    padding: 2%;
    border: 1px solid var(--primarycolor);
    box-shadow: 0px 0px 10px 5px var(--primarycolorhalf);
}

.more-content-header {
    width: 100%;
    padding: 0;
    grid-area: a;
    text-align: center;
}

.more-content-image {
    width: 100%;
    grid-area: b;
}

.more-content-info {
    grid-area: c;
    width: 100%;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.more-content-btn {
    grid-area: d;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 75%;
    place-content: end center;

}

.more-content-btn a {
    display: inline-block;
    margin-bottom: 5px;
    padding: 10px 20px;
    background-color: var(--primarycolor);
    color: #fff;
    text-align: center;
    text-decoration: none;
    width: 100%;
}

.more-content-image {
    grid-area: b;
    width: 100%;
    height: 100%;
    max-height: 300px;
    object-fit: cover;
}

.more-content-btn a:hover {
    transform: scale(1.05);
    transition: transform 0.5s ease;
}

@media screen and (max-width: 768px) {

    .more-content-wrapper {
        width: 90%;
        display: block;
    }

    .intro-section h1 {
        padding-top: 20px;
        font-size: 0.75rem;
    }

    .content-section {
        background-color: var(--accentcolor);
        display: grid;
        grid-template-columns: 34% 24% 14% 24%;
        grid-template-areas:
            "b b a a" "c c c c" "d d d d";
        grid-gap: 1%;
        padding: 3%;
        border: 1px solid var(--primarycolor);
        place-items: center;
        margin-top: 20px;
    }
}