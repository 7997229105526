.contact-container {
    padding-bottom: 10px;
}

.contact-description-container {
    margin: 0 auto;
    padding: 1%;
}
.contact-container-wrapper {
    width: 100%;
    padding-top: 50px;
    padding-left: 5%;
    padding-right: 5%;
}

@media screen and (max-width: 767px) {
    .contact-container-wrapper {
        padding-right: 5%;
        padding-left: 5%;
}
    }