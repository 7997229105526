// ChaosAnimation.scss

.chaos-animation {
    width: 100%;
    display: flex;
    position: relative;
    height: 20px;
    overflow: hidden;

    .animated-phrase {
        position: absolute;
        font-size: 1.5rem;
        font-weight: bold;
        color: var(--primarycolor);
        text-shadow: 1px 1px 0 white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white;
        white-space: nowrap;
        height: 20px;
        width: 120px;
    }

    .life-phrase {
        font-family: var(--ultra-font);
        letter-spacing: 3px; /* Adjust spacing as desired */
        text-align: center;
        color: var(--primarycolor);
        font-size: 4rem;
        text-shadow: 2px 2px 0 var(--accentcolor), -2px -2px 0 var(--accentcolor), 2px -2px 0 var(--accentcolor), -2px 2px 0 var(--accentcolor);
        white-space: nowrap;
    }
}

@media (max-width: 768px) {



    .chaos-animation {
        width: 100%;

        .animated-phrase {
            font-size: 1rem;
        }

        .life-phrase {
            letter-spacing: 2px; /* Adjust spacing as desired */
            font-size: 2rem;
        }
    }

}