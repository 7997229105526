﻿// Sunset Sky
$sunset-gradient: linear-gradient(to bottom, #f4511e, #ff9800, #ffeb3b, #4a6572);

// Ocean Depths
$ocean-gradient: linear-gradient(to bottom, #4fc3f7, #007bff, #2c3e50);

// Retro Vibes
$retro-gradient: linear-gradient(to bottom right, #ff7e5f, #ffb74d);

// Marble Texture
$marble-gradient: radial-gradient(circle, #fff, #f5f5f5, #e0e0e0, #d9d9d9);

// Holographic Sheen
$holographic-gradient: radial-gradient(circle, #a18fff, #7654f5, #4c32fb);

// Neon Cityscape
$neon-gradient: linear-gradient(to bottom right, #45006f, #004abf, #f50057);

// Mountain Landscape
$mountain-gradient: linear-gradient(to top, #558b2f, #a6c954, #e6e6e6);

// Watercolor Wash
$watercolor-gradient: linear-gradient(to top right, #f7cac9, #f9d9b3, #fee1d3);

// Cyberpunk Glitch
$cyberpunk-gradient: linear-gradient(45deg, #00f5ff, #004de6);

// Copper Patina
$copper-gradient: radial-gradient(circle, #c8765b, #a5573d);

// Background Usng site colors
$background-gradient: linear-gradient(to bottom, var(--backgroundcolor), #fff, var(--backgroundcolor));

// Container Backgrounds
$container-gradient: radial-gradient(circle, var(--accentcolor), var(--altaccentcolor), var(--accentcolor));
$accent-gradient: radial-gradient(circle, var(--accentcolor), var(--altaccentcolor), var(--accentcolor));
$altaccent-gradient: radial-gradient(circle, var(--altaccentcolor), var(--accentcolor), var(--altaccentcolor));

// Header Top Down
$header-gradient: linear-gradient(to bottom, var(--primarycolor), var(--backgroundcolor));

// Background Usng site colors
$contact-footer-gradient: linear-gradient(to bottom, var(--backgroundcolor), var(--primarycolor), var(--backgroundcolor));

// Button Hover
$button-hover-gradient: linear-gradient(to bottom,var(--primarycolor), var(--backgroundcolor), var(--primarycolor));

$ocean-gradient: linear-gradient(to bottom, #4fc3f7, #007bff, #2c3e50);

// Footer Top Down
$footer-gradient: linear-gradient(to bottom, var(--backgroundcolor), var(--primarycolor), var(--primarycolor) );
