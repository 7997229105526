.pricing-container-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Single column on mobile */
    justify-content: space-between;
    grid-template-areas: "calc calc" "intro intro" "insurance self";

    h1 {
        text-align: center;
    }
}
.pricing-image-container {
    grid-area: calc;
    margin-bottom: 10px;
}
#pricing-header-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    margin: 0 auto;
    display: block;
    object-position: 65% 75%;
}

#pricing-closing-image {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 300px;
    object-position: 75% 5%;
    place-self: center;
    object-fit: cover;
    align-self: center;
    margin: 0 auto;
    display: block;
}

.pricing-intro-container {
    grid-area: intro;
    margin: 2.5%;
    padding: 2.5%;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;


    a {
        color: #000;
        text-decoration: underline;

        &:hover {
            color: var(--primarycolor);
        }
    }
}

    .pricing-insurance-pay-container {
        grid-area: insurance;
        margin: 5%;
        padding: 5%;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        border-right: 2px solid var(--primarycolor);

        li {
            font-weight: 600;
        }
    }

.pricing-self-pay-container {
    grid-area: self;
    margin: 5%;
    padding: 5%;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-left: 2px solid var(--primarycolor);

    li {
        font-weight: 600;
    }
}

.pricing-insurance-pay-container,
.pricing-self-pay-container {
    width: 100%;
    margin: 20px auto;




    // The Pinline itself
    h2.pinline {
        text-align: center;
        display: block;
        overflow: hidden;
        font-size: 1.1em;
        font-weight: 100;
        margin: 20px 0;
        font-weight: 700;
        text-transform: uppercase;

        span {
            position: relative;
            display: inline-block;
            line-height: 1;

            &:before, &:after {
                content: "";
                position: absolute;
                top: 50%;
                height: 3px;
                background: var(--primarycolor);
                width: 99999px;
            }

            &:before {
                left: 100%;
                margin-left: 20px;
            }

            &:after {
                right: 100%;
                margin-right: 20px;
            }
        }
    }
}

    @media (max-width: 768px) {
        #pricing-closing-image{
            width: 100%;
            height: auto;
        }

        
        #pricing-header-image {
            height: 200px;
        }
        .pricing-container-wrapper {
            display: block;
            width: 100%;
            margin: 0 auto;
        }

        .pricing-self-pay-container,
        .pricing-insurance-pay-container {
            border: 0px;
        }

        .pricing-self-pay-container {

            li {
                font-weight: 600;
                font-size: 1.2rem;
            }
        }
    }