.faq-intro-container {
    grid-area: intro;
    margin: 0;
    margin-left: 2.5%;
    margin-right: 2.5%;
    padding: 0;
    padding-left: 2.5%;
    padding-right: 2.5%;

    h1 {
        text-align: center;
    }
}
/* FAQ.css */

.faq-container-wrapper {
    background-image: url(../../assets/faq/faq-background.webp);
    padding: 25px;
}

.faq-container h2 {
    font-size: 2rem;
    color: var(--primarycolor); /* Set the text color */
    text-align: center;
    font-weight: 800;
    margin-bottom: 20px;
}

.faq-item {
    cursor: pointer;
    max-width: 50%;
    transition: background-color 0.3s ease;
    color: #fff;
    justify-self: flex-end;
    align-self: center;
    margin: 0 auto;
    margin-bottom: 15px;
}

.faq-item:hover {
    transform: scale(1.025);
    transition: transform 0.3s ease;
}

.faq-question {
    padding: 15px;
    font-size: 1.5rem;
    font-weight: 1000;
    font-weight: bold;
    border: 1px solid var(--primarycolorhalf);
    color: var(--backgroundcolor);
    background-color: var(--primarycolor);
}

.faq-answer {
    padding: 15px;
    border: 1px solid var(--primarycolor);
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--primarycolor);
    background-color: #fff;
}


@media screen and (max-width: 768px) {
    .faq-item-container {
        position: relative;
        z-index: 1;

        h2 {
        font-size: 1.5rem;
        font-weight: 800;
    }
 }

    .faq-question {
        padding: 15px;
        font-size: 1.1rem;
        font-weight: 600;
        margin-top: 20px;
    }

    .faq-answer {
        font-size: 1rem;
    }

    .faq-item {
        max-width: 90%;
    }
}