
/* ProductList.scss */
.recommendation-product-container {
    align-items: flex-start;
    place-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    padding-left: 1%;
    padding-right: 1%;
    grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
    height: 100%;
    gap: 1%;
    row-gap: 10px;
    flex: 1 0 auto;
}


.recommendation-item-container {
    display: flex;
    margin: 5px;
    flex-direction: column;
    justify-content: space-evenly;
    max-height: 300px;
    max-width: 189px;
    height: 300px;
    aspect-ratio: 5 / 8;
    flex: 1 0 auto;
    overflow: hidden; /* Ensure each item has its own container */
    transition: transform 0.3s ease-in-out;
    background-color: #fff;
    border: 3px solid var(--primarycolor);
    box-shadow: 3px 3px var(--primarycolorhalf);
}

.recommendation-item-container:hover {
    transform: scale(1.05);
    box-shadow: 5px 5px var(--primarycolorhalf);
}

.product-image {
    display: block;
    max-width: 175px;
    max-height: 120px;
    height: auto;
    width: auto;
    object-fit: contain;
    margin: 0 auto;
    flex: 1 0 auto;
}

.recommendation-product-name {
    place-self: flex-start;
    margin: 0 auto;
    margin-top: 5px;
    width: 95%;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    flex: 1 0 auto;
}
.product-description {
    display: block;
    font-size: .75rem;
    margin-top: 10px;
    text-align: center;
    align-self: center;
    width: 95%;
}

.product-link {
    display: block;
    margin: 0 auto;
    margin-bottom: 5px;
    place-self: flex-end;
    width: 75%;
    font-size: .9rem;
    padding: 4%;
    text-align: center;
    background-color: var(--primarycolor);
    color: #fff;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    flex-shrink: 0;
}

.product-item-container {
    display: flex;
    position: relative;
    width: 100%;
    padding-top: 100%; // Set the aspect ratio (1:1)
}

.product-link:hover {
    background-color: var(--altaccentcolor);
    color: var(--primarycolor);

}

@media (max-width: 768px) {
    .recommendation-product-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr); // Two columns
grid-column-gap: 2%;
    }

    .recommendation-item-container:hover {
        transform: scale(1.00);
        box-shadow: 3px 3px var(--primarycolorhalf);
    }
}
